"use client";
import "@styles/globals.css";
import QueryProvider from "./QueryProvider";
import ReduxProvider from "./ReduxProvider";
import { GoogleAnalytics } from "@next/third-parties/google";
import { useEffect } from "react";
// import useAdjust from "@hooks/useAdjust";
import dynamic from "next/dynamic";

const AdjustInitializer = dynamic(() => import("@hooks/useAdjust"), {
  ssr: false,
});

const RootLayout = ({ children }) => {
  // const { trackEvent } = useAdjust("3plp4ofteam8");

  // useEffect(() => {
  //   // Example of tracking an event on page load
  //   trackEvent("EVENT_TOKEN");
  // }, []);
  return (
    <html lang="en">
      <ReduxProvider>
        <QueryProvider>
          <AdjustInitializer />

          <body> {children}</body>
          <GoogleAnalytics gaId="G-5E07BFZDQN" />
        </QueryProvider>
      </ReduxProvider>
    </html>
  );
};

export default RootLayout;
