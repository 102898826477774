import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  events: {},
  totalAmount: 0,
  selectedWave: null, // Add this line
};

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { eventID, item } = action.payload;
      if (!state.events[eventID]) {
        state.events[eventID] = [];
      }
      state.events[eventID].push(item);
      state.totalAmount += item.price;
    },
    removeItem: (state, action) => {
      const { eventID, waveID } = action.payload;
      const eventItems = state.events[eventID];
      if (eventItems) {
        const itemIndex = eventItems.findIndex((i) => i.waveID === waveID);
        if (itemIndex !== -1) {
          state.totalAmount -= eventItems[itemIndex].price;
          eventItems.splice(itemIndex, 1);
        }
      }
    },
    setSelectedWave: (state, action) => {
      // Add this function
      state.selectedWave = action.payload;
    },
    updateItem: (state, action) => {
      const { eventID, item } = action.payload;
      const eventItems = state.events[eventID];
      if (eventItems) {
        const itemIndex = eventItems.findIndex((i) => i.waveID === item.waveID);
        if (itemIndex !== -1) {
          // Update the item quantity and total amount
          const updatedQuantity =
            eventItems[itemIndex].quantity + item.quantity;
          if (updatedQuantity === 0) {
            // Remove the item if the updated quantity is 0
            state.totalAmount -= eventItems[itemIndex].price;
            eventItems.splice(itemIndex, 1);
          } else {
            eventItems[itemIndex] = {
              ...eventItems[itemIndex],
              quantity: updatedQuantity,
            };
            state.totalAmount += item.quantity * item.price;
          }
        } else if (item.quantity > 0) {
          // Add a new item if it doesn't exist and the quantity is positive
          eventItems.push(item);
          state.totalAmount += item.price;
        }
      } else if (item.quantity > 0) {
        // Initialize the event items array and add the new item only if the quantity is positive
        state.events[eventID] = [item];
        state.totalAmount += item.price;
      }
    },
    resetCart: (state) => {
      state.events = {};
      state.totalAmount = 0;
      state.selectedWave = null; // Also reset the selectedWave
    },
    setGuestFirst: (state, action) => {
      const { eventID, waveID, first } = action.payload;
      const eventItems = state.events[eventID];
      if (eventItems) {
        const itemIndex = eventItems.findIndex((i) => i.waveID === waveID);
        if (itemIndex !== -1) {
          eventItems[itemIndex].guestFirst = first;
        }
      }
    },
    setGuestEmail: (state, action) => {
      const { eventID, waveID, email } = action.payload;
      const eventItems = state.events[eventID];
      if (eventItems) {
        const itemIndex = eventItems.findIndex((i) => i.waveID === waveID);
        if (itemIndex !== -1) {
          eventItems[itemIndex].guestEmail = email;
        }
      }
    },
  },
});

export const {
  addItem,
  removeItem,
  updateItem,
  resetCart,
  setSelectedWave,
  setGuestEmail,
  setGuestFirst,
} = cart.actions; // Export the setSelectedWave action

export default cart.reducer;
