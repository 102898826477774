import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      id: "",
      email: "",
      fname: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
    },
    tokens: {
      idToken: "",
      refreshToken: "",
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
